import { PageLoader } from 'components/PageLoader/PageLoader';
import { lazy, LazyExoticComponent, ReactElement, Suspense } from 'react';

export type Modules = 'HOME';

type Route = {
  path: string;
  Element: LazyExoticComponent<() => ReactElement>;
  children?: Route[];
};

type RoutesProps = Record<Modules, Route>;

export const HomePage = lazy(() => import('views/Home'));

export const BASE_NAME = 'link-admin';

export const homePath = `/${BASE_NAME}`;

export const Routes: RoutesProps = {
  HOME: { path: homePath, Element: HomePage },
};

export const modules = [Routes.HOME];

export const routesConfig = modules.map(({ path, Element }) => ({
  path,
  element: (
    <Suspense fallback={<PageLoader />}>
      <Element />
    </Suspense>
  ),
}));
