import {
  useAppHeader,
  useAuthenticationService,
  useUserMetadata,
} from 'admin-portal-shared-services';
import { useEffect } from 'react';

export const AppHeader = () => {
  const [, setAppHeaderConfig] = useAppHeader();
  const { data } = useUserMetadata();
  const authentication = useAuthenticationService();
  const { user_country } = authentication.getUserCountryAndLanguage();

  useEffect(() => {
    setAppHeaderConfig({
      countryOptions: data?.supportedCountries || [],
      vendorSelect: false,
      countrySelect: true,
      defaultCountry: `${user_country}`,
    });
  }, [setAppHeaderConfig, data?.supportedCountries, user_country]);

  return null;
};
